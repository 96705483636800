<template>
    <Loader v-if="loading" class="bg-white card"></Loader>
    <div v-else class="bg-white card bs-11">
        <div class="fl-te-c">
            <h3>Select Address</h3>
            <btn @click="openModal" size="sm" class="mr-2" icon="fa fa-plus" text="New Address"/>
        </div>
        <div class="mt-2">
            <div v-for="(address, index) in addresses" v-bind:key="index" @click="setActive(index)">
                <label>
                    <div class="card-input-address" :class="[(activeIndex === index) ? 'active':'']">

                        <div class="fl-te-c">
                            <div class="fl-x-c">
                                <div class="radio-container">
                                    <input type="radio" name="address" class="card-input-element" :value="address"
                                           v-model="radio"/>
                                    <span class="checkmark"></span>
                                </div>
                                <strong><span class="text-muted">{{ address.name }}</span></strong>
                            </div>
                            <btn size="sm" @click="editItem(address)" icon="fa fa-pencil"/>
                        </div>
                        <div class="card-body ">
                            <span class="text-muted">{{ address.address_line_1 }}</span><br>
                            <span class="text-muted">{{ address.address_line_2 }}</span><br>
                            <span class="text-muted">{{ address.locality }} </span><br>
                            <span class="text-muted">{{ address.district }}, {{ address.state }}</span><br>
                            <span class="text-muted">{{ address.country }}</span><br>
                            <span class="text-muted">{{ address.pin_code }}</span>
                        </div>
                    </div>

                </label>
            </div>
            <div class="fl-cc" v-if="radio">
                <btn icon="fa fa-send" text=" Place my order" @click="selectedAddress"/>
            </div>
        </div>

        <modal title="Address" @hidden="model={}" ref="modal">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(AddAddress)">
                    <div class="row">
                        <div class="col-md-6">
                            <validated-input name="House Name / Flat No" label="House Name / Flat No"
                                             v-model="model.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Address line 1" label="Address line 1"
                                             v-model="model.address_line_1"
                                             :rules="rules.address_line_1"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Address line 2" label="Address line 2"
                                             v-model="model.address_line_2"
                                             :rules="rules.address_line_2"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Locality" label="Locality" v-model="model.locality"
                                             :rules="rules.locality"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="District" label="District" v-model="model.district"
                                             :rules="rules.district"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="State" label="State" v-model="model.state"
                                             :rules="rules.state"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Country" label="Country" v-model="model.country"
                                             :rules="rules.country"/>
                        </div>
                        <div class="col-md-6">
                            <validated-mask-input mask="######" name="Pin code" label="Pin code"
                                                  v-model="model.pin_code"
                                                  :rules="rules.pin_code"/>
                        </div>
                        <div class="col-md-6">
                            <btn type="submit" @click="AddAddress">
                                Save
                            </btn>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';
import Loader from '../../../views/loader/loader';

export default {
    name: 'AddressList',
    components: { Loader },
    data () {
        return {
            addresses: [],
            loading: false,
            radio: null,
            activeIndex: 'undefined',
            model: {
                user_id: '',
                name: '',
                username: '',
                address_line_1: '',
                address_line_2: '',
                locality: '',
                district: '',
                state: '',
                country: '',
                pin_code: ''
            },
            rules: {
                name: {
                    required: true
                },
                address_line_1: {
                    required: true
                },
                district: {
                    required: true
                },
                state: {
                    required: true
                },
                country: {
                    required: true
                },
                pin_code: {
                    required: true
                }
            }
        };
    },
    beforeMount () {
        this.loadData();
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        openModal () {
            this.$refs.modal.show();
        },
        setActive (index) {
            this.activeIndex = index;
        },
        setData (response) {
            this.loading = true;
            console.log('this.res', response);
            if (response.data.User) {
                this.addresses = response.data.Addresses;
                this.user = response.data.User;
                this.loading = false;
            }
        },

        async loadData () {
            this.loading = true;
            const id = this.currentUser.id;
            this.model.user_id = id;
            const response = await axios.post(urls.userAddress.details + '?id=' + id);
            this.loading = false;
            this.setData(response);
        },

        async selectedAddress () {
            this.loading = true;
            const id = this.currentUser.id;
            const sentData = {
                user_id: id,
                user_address_id: this.radio.id
            };
            const response = await axios.post(urls.shopOrder.placeorder, sentData);
            console.log('response:>>>', response.data.data);
            const data = response.data.data;
            if (response.data.Error === false) {
                const mobile = data.shop_mobile;
                const address = data.Address;
                const items = data.Products;
                const totalAmount = data.total_amount;

                console.log('products:\n', items);

                let product = '';
                let j = 1;
                for (let i = 0; i < items.length; i++) {
                    j = i + 1;
                    product += j + '. ' + items[i].products + ' - ' + items[i].quantity + ' - Rs.' + (items[i].total_price) / 100 + '/-' + '\n';
                }
                const printProd = '*Product - Quantity - Amount* \n\n' + product + '\n\n';
                const printAmount = '*Total Amount* : ```Rs.' + (totalAmount) / 100 + '/-```' + '\n\n';
                const printAddress = '*Customer Address*\n\n' +
                    address[0].name + '\n' +
                    address[0].address_line_1 + '\n' +
                    address[0].address_line_2 + '\n' +
                    address[0].locality + '\n' +
                    address[0].district + ',' + address[0].state + '\n' +
                    address[0].country + ',' + address[0].pin_code + '\n';
                const printData = printProd + printAmount + printAddress;
                console.log(printProd + printAmount + printAddress);

                window.open(
                    'https://api.whatsapp.com/send?phone=' + mobile + `&text=${escape(printData)}`
                );
                this.$router.push({ path: '/success/' });
            } else {
                alert('Something went wrong..!');
            }
        },
        async AddAddress () {
            if (this.model.id) {
                const url = urls.userAddress.update;
                const response = await axios.form(url, this.model);
                console.log('response', response);

                const arr = this.addresses;
                const index = arr.map(function (o) {
                    return o.id;
                }).indexOf(this.model.id);

                arr.splice(index, 1);
                arr.splice(index, 0, response.data.obj[0]);
                console.log('response-inx', index, 'array', arr);

                this.$refs.modal.close();
            } else {
                const url = urls.userAddress.create;
                const response = await axios.form(url, this.model);
                console.log('add response', response);
                this.addresses.push(response.data.obj);
                this.$refs.modal.close();
            }
        },
        async editItem (item) {
            this.model = {
                id: item.id,
                user_id: this.currentUser.id,
                name: item.name,
                address_line_1: item.address_line_1,
                address_line_2: item.address_line_2,
                locality: item.locality,
                district: item.district,
                state: item.state,
                country: item.country,
                pin_code: item.pin_code
            };
            this.openModal();
        }

    }
};
</script>

<style scoped>
label {
    width: 100%;
}

.card-input-address {
    margin-bottom: 2rem;
    padding: 1rem;
}

.card-input-address:hover {
    cursor: pointer;
}

.card-input-address {
    box-shadow: 0 0 1px 1px var(--border-color);
}

.card-input-address.active {
    box-shadow: 0 0 1px 1px var(--color-primary);
}

.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #cfcfcf;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
    background-color: #cfcfcf;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
    background-color: var(--color-primary);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    left: 5px;
    border-radius: 50%;
    content: '\2713';
    color: white;
    font-size: 18px;
}
</style>
